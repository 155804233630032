import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import LeafSvg from '../Svg/LeafSvg';
import CalendarSvg from '../Svg/CalendarSvg';
import { Link } from 'gatsby';
import { useForm } from '@formspree/react';

import { cssVariables } from '../config';

const Wrapper = styled.div`
  padding: 57px 20px 60px 20px;
  background: linear-gradient(
    124deg,
    rgba(229, 245, 236, 0.3),
    rgba(174, 214, 241, 0.3)
  );
  color: ${cssVariables.color.gray1};
  font-family: fira-sans, sans-serif;
  font-weight: 400;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    padding: 137px 20px 80px 20px;
  }
  p {
    font-size: 18px;
    line-height: 1.444;
    letter-spacing: 0;
    margin: 0;
  }
  h3 {
    font-family: pacifico, sans-serif;
    font-size: 24px;
    margin: 0 0 20px 0;
    font-weight: 400;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    flex-direction: row;
    align-items: start;
  }
`;

const Box = styled.div`
  width: 100%;
  background: #e5f5ec;
  border: 1px solid ${cssVariables.color.green1};
  border-radius: 12px;
  position: relative;
  h3 {
    color: ${cssVariables.color.green1};
  }
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    width: 480px;
    margin-right: 120px;
    margin-left: auto;
  }
`;

const BoxBody = styled.div`
  padding: 73px 20px 40px 20px;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    padding: 73px 50px 50px 50px;
  }
`;

const IconContainer = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${cssVariables.color.green1};
  background: white;
  flex: none;
  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: -40px;
      left: calc(50% - 40px);
    `}
`;

const Form = styled.form`
  margin-top: 30px;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    margin-right: auto;
    margin-top: 0px;
  }
  h3 {
    color: ${cssVariables.color.green1};
    @media (min-width: ${cssVariables.breakPoints.tablet}) {
      font-size: 48px;
      margin-bottom: 22px;
    }
  }

  button {
    height: 52px;
    width: 100%;
    border: 1px solid #007a38;
    border-radius: 3px;
    color: white;
    background: #7dcea0;
    font-size: 18px;
    margin-top: 30px;
    cursor: pointer;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    flex-direction: row;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 22px;
    div {
      width: 100%;
      padding: 0 10px;
    }
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    margin-bottom: 0px;
  }
  label {
    font-size: 18px;
    line-height: 1.444;
  }
  input,
  textarea {
    margin: 9px 0 0 0;
    border: 1px solid #007a38;
    border-radius: 3px;
    height: 52px;
    display: inline-flex;
    padding-left: 16px;
    font-family: fira-sans, sans-serif;
  }
  input::placeholder,
  textarea::placeholder {
    color: #878787;
    font-family: fira-sans, sans-serif;
    font-size: 16px;
  }
  textarea {
    height: 180px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 15px;
    line-height: 1.5;
  }
`;

const FormRadio = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 18px;
    margin: 0 0 15px 0;
  }
  label {
    display: inline-flex;
    align-items: center;
    line-height: 1.5;
    margin-bottom: 20px;
    cursor: pointer;
  }
  input[type='radio'] {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    outline: none;
    border: 1px solid #007a38;
    background: white;
    margin: 0 10px 0 0;
    flex: none;
    cursor: pointer;
  }
  input[type='radio']:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 17% auto;
    border-radius: 50%;
  }
  input[type='radio']:checked:before {
    background: #7dcea0;
  }
`;

const FormCheck = styled.div`
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      display: inline-block;
    }
    cursor: pointer;
    input {
      -webkit-appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      outline: none;
      border: 1px solid #007a38;
      background: white;
      margin: 0 10px 0 0;
      flex: none;
      position: relative;
      cursor: pointer;
    }
  }
  input:checked:after {
    content: '\\2714';
    font-size: 14px;
    top: -3px;
    left: 1px;
    color: #7dcea0;
    position: absolute;
  }
`;

const SuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  width: 380px;
  h3 {
    margin: 15 0 20px 0;
  }
  div:last-child {
    width: 380px;
  }
  @media (min-width: ${cssVariables.breakPoints.tablet}) {
    flex-direction: row;
    align-items: start;
    width: 980px;
    margin: 0 auto;
    div:last-child {
      margin-left: 20px;
    }
    h3 {
      margin-top: 20px;
    }
  }
`;

const ParkingSizesEnum = {
  HUNDRED: '100',
  TWOHUNDRED: '200',
};

const SearchPeriodsEnum = {
  NOW: 'now',
  IN_NEXT_TWELVE_MONTHS: 'in-next-twelve-months',
  LATER: 'later',
};

const WagonTypesEnum = {
  CARAVAN: 'caravan',
  MOBILE_HOME: 'mobile-home',
};

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    parkingSize: ParkingSizesEnum.HUNDRED,
    searchPeriod: SearchPeriodsEnum.NOW,
    wagonType: WagonTypesEnum.CARAVAN,
  });

  const [state, handleSubmit] = useForm('meqvoldy');
  if (state.succeeded) {
    return (
      <Wrapper>
        <SuccessMessage>
          <IconContainer>
            <CalendarSvg />
          </IconContainer>
          <div>
            <h3>Anfrage erhalten!</h3>
            <p>
              Wir melden uns bei ihnen sobald Kapazitäten für ihre Anfrage,
              entsprechend ihrer Suche, vorhanden sind.
            </p>
          </div>
        </SuccessMessage>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Box>
          <IconContainer absolute>
            <LeafSvg></LeafSvg>
          </IconContainer>
          <BoxBody>
            <h3>Unsere Stellplätze</h3>
            <p>
              Vielen Dank, dass Sie sich für einen unserer Stellplätze
              interessieren! Bitte füllen Sie das Formular für die Anfrage aus
              und wir melden uns in Kürze per E-Mail oder Telefon zurück!
            </p>
          </BoxBody>
        </Box>
        <Form onSubmit={handleSubmit}>
          <h3>Stellplatz Anfrage</h3>
          <FormRow>
            <FormGroup>
              <label htmlFor="first-name">Vorname</label>
              <input
                id="first-name"
                type="text"
                name="firstname"
                required
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    firstname: event.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="last-name">Nachname</label>
              <input
                id="last-name"
                type="text"
                name="lastname"
                required
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    lastname: event.target.value,
                  })
                }
              />
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup>
              <label htmlFor="email-address">E-Mail Adresse</label>
              <input
                id="email-address"
                type="email"
                placeholder="z.B. max@mustermann.de"
                name="email"
                required
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    email: event.target.value,
                  })
                }
              />
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup>
              <label htmlFor="phone">Telefonnummer</label>
              <input
                id="phone"
                type="tel"
                name="phone"
                required
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    phone: event.target.value,
                  })
                }
              />
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormRadio>
              <p>Stellplatz-Größe</p>
              <label htmlFor="parkingSize_1">
                <input
                  type="radio"
                  id="parkingSize_1"
                  name="parking-size"
                  value={ParkingSizesEnum.HUNDRED}
                  checked={formValues.parkingSize === ParkingSizesEnum.HUNDRED}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      parkingSize: event.target.value,
                    })
                  }
                />
                100m² - 200m²
              </label>
              <label htmlFor="parkingSize_2">
                <input
                  type="radio"
                  id="parkingSize_2"
                  name="parking-size"
                  value={ParkingSizesEnum.TWOHUNDRED}
                  checked={
                    formValues.parkingSize === ParkingSizesEnum.TWOHUNDRED
                  }
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      parkingSize: event.target.value,
                    })
                  }
                />
                über 200m²
              </label>
            </FormRadio>
            <FormRadio>
              <p>Ich suche ab:</p>
              <label htmlFor="searchPeriod_1">
                <input
                  type="radio"
                  id="searchPeriod_1"
                  name="searchPeriod_1"
                  value={SearchPeriodsEnum.NOW}
                  checked={formValues.searchPeriod === SearchPeriodsEnum.NOW}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      searchPeriod: event.target.value,
                    })
                  }
                />
                Sofort
              </label>
              <label htmlFor="searchPeriod_2">
                <input
                  type="radio"
                  id="searchPeriod_2"
                  name="searchPeriod_2"
                  value={SearchPeriodsEnum.IN_NEXT_TWELVE_MONTHS}
                  checked={
                    formValues.searchPeriod ===
                    SearchPeriodsEnum.IN_NEXT_TWELVE_MONTHS
                  }
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      searchPeriod: event.target.value,
                    })
                  }
                />
                In den nächsten 12 Monaten
              </label>
              <label htmlFor="searchPeriod_3">
                <input
                  type="radio"
                  id="searchPeriod_3"
                  name="searchPeriod_3"
                  value={SearchPeriodsEnum.LATER}
                  checked={formValues.searchPeriod === SearchPeriodsEnum.LATER}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      searchPeriod: event.target.value,
                    })
                  }
                />
                Später
              </label>
            </FormRadio>
          </FormRow>
          <FormRow>
            <FormRadio>
              <p>Ich suche einen Platz für:</p>
              <label htmlFor="wagonType_1">
                <input
                  type="radio"
                  id="wagonType_1"
                  name="wagonType_1"
                  value={WagonTypesEnum.CARAVAN}
                  checked={formValues.wagonType === WagonTypesEnum.CARAVAN}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      wagonType: event.target.value,
                    })
                  }
                />
                einen Wohnwagen
              </label>
              <label htmlFor="wagonType_2">
                <input
                  type="radio"
                  id="wagonType_2"
                  name="wagonType_2"
                  value={WagonTypesEnum.MOBILE_HOME}
                  checked={formValues.wagonType === WagonTypesEnum.MOBILE_HOME}
                  onChange={(event) =>
                    setFormValues({
                      ...formValues,
                      wagonType: event.target.value,
                    })
                  }
                />
                Ein Mobilheim
              </label>
            </FormRadio>
            <FormGroup>
              <label htmlFor="yearOfConstruction">
                Mein Objekt ist Baujahr
              </label>
              <input
                id="yearOfConstruction"
                type="text"
                placeholder="z.B. 2018"
                name="yearOfConstruction"
                required
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    yearOfConstruction: event.target.value,
                  })
                }
              />
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup>
              <label htmlFor="comment">Ihre Nachricht an uns</label>
              <textarea
                id="comment"
                placeholder="Wenn Sie uns etwas mitteilen wollen oder Fragen zu uns oder unserem Campingplatz haben, ist das der richtige Platz dafür :)"
                rows="8"
                name="comment"
                required
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    comment: event.target.value,
                  })
                }
              />
            </FormGroup>
          </FormRow>
          <FormCheck>
            <label htmlFor="privacy-check">
              <input
                type="checkbox"
                id="privacy-check"
                name="Privacy Policy"
                required
              />
              <span>
                Ich habe die{' '}
                <Link to="/datenschutz/" activeClassName="active">
                  Datenschutzbestimmungen
                </Link>{' '}
                gelesen und verstanden
              </span>
            </label>
          </FormCheck>
          <button type="submit">Anfrage abschicken</button>
        </Form>
      </Container>
    </Wrapper>
  );
};

export default ContactForm;
