import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import PageHead from '../components/PageHead';
import Navi from '../components/Navi';
import Footer from '../components/Footer';
import GoogleMap from '../components/GoogleMap';
import MetaFooter from '../components/MetaFooter';
import ContactForm from '../components/ContactForm';

export default () => (
  <Layout>
    <Header />
    <PageHead />
    <Navi />
    <ContactForm />
    <Footer />
    <GoogleMap />
    <MetaFooter />
  </Layout>
);
