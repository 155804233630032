import React from 'react';

export default () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
      <g fill="none">
        <path
          fill="#196F3D"
          d="M27.773 39.111l-5.551-5.812 2.286-2.393 3.265 3.419 8.164-8.547 2.285 2.393z"
        />
        <path
          fill="#007A38"
          d="M38.33 4.988H30V1.664c0-.917-.747-1.661-1.667-1.661s-1.666.744-1.666 1.661v3.324H13.334V1.664A1.567 1.567 0 0012.94.528a1.577 1.577 0 00-1.272-.525 1.578 1.578 0 00-1.14.393 1.568 1.568 0 00-.527 1.268v3.324H1.67a1.578 1.578 0 00-1.14.392 1.568 1.568 0 00-.526 1.27v29.908c-.025.416.116.825.394 1.136a1.577 1.577 0 001.272.526h16.665v-3.323H3.336V11.634h33.328v8.308h3.333V6.65a1.567 1.567 0 00-.394-1.136 1.577 1.577 0 00-1.272-.525z"
        />
      </g>
    </svg>
  );
};
